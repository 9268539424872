<template>
  <div class="login-wrapper">
    <div class="login-logo">
      <div class="copyright">{{ $t('login.copyright') }}</div>
    </div>
    <div class="login-img" />
    <div class="login-form">
      <div class="title"><img :src="logoUrl" /></div>
      <transition name="fade-transform" mode="out-in">
        <a-form
          v-if="show === 'login'"
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item name="account" :label="$t('login.account.i')">
            <a-input
              autocomplete="off"
              v-model:value="loginForm.account"
              :placeholder="$t('login.account.placeholder')"
              @keyup.enter="handleLogin"
            />
          </a-form-item>
          <a-form-item name="password" :label="$t('login.password')">
            <a-input-password
              v-model:value="loginForm.password"
              :placeholder="$t('login.password')"
              @keyup.enter="handleLogin"
            />
          </a-form-item>
          <a-form-item name="code" :label="$t('login.code')">
            <a-input
              autocomplete="off"
              v-model:value="loginForm.code"
              :placeholder="$t('login.code')"
              @keyup.enter="handleLogin"
            />
            <div class="code-img">
              <a-spin :spinning="spinning" size="small">
                <a-image
                  :height="30"
                  :preview="false"
                  :src="codeSrc"
                  :fallback="errorImg"
                  @click="getCaptcha"
                />
              </a-spin>
            </div>
          </a-form-item>
        </a-form>
        <a-form
          v-else-if="show === 'forget'"
          ref="forgetForm"
          :model="forgetForm"
          :rules="forgetRules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item name="account" :label="$t('login.account.i')">
            <a-input
              autocomplete="off"
              v-model:value="forgetForm.account"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <a-form-item name="code" :label="$t('login.code')">
            <a-input
              autocomplete="off"
              v-model:value="forgetForm.code"
              :placeholder="$t('login.code')"
            />
            <a-button
              class="code-img"
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-form-item>
        </a-form>
        <div v-else-if="['password', 'forgetPassword'].includes(show)">
          <a-alert
            show-icon
            message="请修改密码："
            type="info"
            style="margin-bottom: 10px"
          />
          <a-form
            ref="passwordForm"
            :model="passwordForm"
            :rules="passwordRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            :validate-trigger="['change', 'blur']"
            :colon="false"
            hide-required-mark
          >
            <a-form-item
              v-if="show === 'password'"
              name="oldPassword"
              label="旧密码"
            >
              <a-input-password
                v-model:value="passwordForm.account"
                placeholder="请输入旧密码"
              />
            </a-form-item>
            <a-form-item name="password" label="新密码">
              <a-input-password
                v-model:value="passwordForm.password"
                placeholder="请输入新密码"
              />
            </a-form-item>
            <a-form-item name="confirmPassword" label="确认密码">
              <a-input-password
                v-model:value="passwordForm.confirmPassword"
                placeholder="请输入确认密码"
              />
            </a-form-item>
          </a-form>
        </div>
        <div v-else-if="show === 'reLogin'">
          <a-alert
            show-icon
            message="修改成功，请重新登录"
            type="success"
            style="margin-bottom: 10px"
          />
        </div>
      </transition>
      <a-button
        class="btn"
        :loading="loading"
        type="primary"
        block
        @click="handleClick"
      >
        {{ btnText[show] }}
      </a-button>
      <div class="forgetPassword">
        <a class="link" @click="handleClickByLink">
          {{ linkText[show] }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Form, Image, Spin } from 'ant-design-vue';
import { randoms } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';
import errorImg from '@/assets/images/error.png';
import loginApi from '@/api/login.js';
import personalApi from '@/api/personal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AAlert: Alert,
    AImage: Image,
    ASpin: Spin,
  },
  data() {
    const required = { required: true, whitespace: true, message: '不能为空' };
    const validAccount = (rule, value) =>
      value.trim()
        ? validMobile(value) || validEmail(value)
          ? Promise.resolve()
          : Promise.reject('请输入合法的手机号或者邮箱')
        : Promise.reject('不能为空');

    return {
      logoUrl: process.env.VUE_APP_PROJECT_LOGIN_LOGO,
      errorImg,
      btnText: {
        login: '立即登录',
        forget: '下一步',
        forgetPassword: '完成',
        password: '完成',
        reLogin: '重新登录',
      },
      linkText: {
        login: '忘记密码',
        forget: '登录',
        forgetPassword: '登录',
      },
      show: 'login',
      loading: false,
      spinning: false,
      codeSrc: '',
      loginForm: { account: '', password: '', key: '', code: '' },
      loginRules: {
        account: { validator: validAccount },
        password: required,
        code: required,
      },
      forgetForm: { account: '', code: '' },
      forgetRules: {
        account: { validator: validAccount },
        code: required,
      },
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      passwordRules: {
        oldPassword: required,
        password: required,
        confirmPassword: {
          validator: (rule, value) =>
            value.trim()
              ? value === this.passwordForm.password
                ? Promise.resolve()
                : Promise.reject('两次输入密码不匹配')
              : Promise.reject('不能为空'),
        },
      },
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      cache: {},
    };
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      this.spinning = true;
      this.loginForm.key = randoms(24, 16);
      loginApi
        .getCaptcha(this.loginForm.key)
        .then(data => (this.codeSrc = data))
        .finally(() => (this.spinning = false));
    },
    handleClick() {
      switch (this.show) {
        case 'login':
          this.handleLogin();
          break;
        case 'forget':
          this.handleNext();
          break;
        case 'password':
        case 'forgetPassword':
          this.handlePassword();
          break;
        case 'reLogin':
          this.handleClickByLink();
          break;
        default:
          break;
      }
    },
    handleClickByLink() {
      this.show = this.show === 'login' ? 'forget' : 'login';
    },
    handleLogin() {
      this.$refs.loginForm.validate().then(() => {
        this.loading = true;
        loginApi
          .login(this.loginForm)
          .then(({ tenantCode, token, user, permissionsList }) => {
            this.$store.commit('account/setTenant', tenantCode);
            this.$store.commit('account/setToken', token.token);
            this.$store.commit('account/setUser', user);
            this.$store.commit('account/setPermissions', permissionsList);
            this.$message.success('登录成功');
            this.$router.push('/');
          })
          .catch(() => {
            this.loginForm.code = '';
            this.getCaptcha();
          })
          .finally(() => (this.loading = false));
      });
    },
    handleGetCode() {
      this.$refs.forgetForm.validateFields('account').then(() => {
        loginApi.getTenant({ account: this.forgetForm.account }).then(data => {
          if (data) {
            this.$store.commit('account/setTenant', data);
            this.btn = { disabled: true, title: '重新获取(60s)' };
            let count = 60;
            let timer = setInterval(() => {
              if (count === 1) {
                this.btn = { disabled: false, title: '获取验证码' };
                clearInterval(timer);
                timer = null;
              } else {
                count--;
                this.btn.title = `重新获取(${count}s)`;
              }
            }, 1000);
            loginApi.getCode(this.forgetForm.account).then(() => {
              this.$message.success('验证码已发送，请注意查收');
            });
          } else {
            this.$message.error('该账号不存在企业信息，请重新输入');
          }
        });
      });
    },
    handleNext() {
      this.$refs.forgetForm.validate().then(() => {
        this.loading = true;
        loginApi
          .verifyCode(this.forgetForm)
          .then(data => {
            if (data) {
              loginApi
                .verifyAccount(this.forgetForm.account)
                .then(d => {
                  if (d) {
                    this.cache.user = { userId: d.id };
                    this.show = 'forgetPassword';
                  } else {
                    this.$message.error('账号不存在，请重新输入');
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              this.loading = false;
              this.$message.error('验证失败，请重新获取验证码');
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    handlePassword() {
      let temp = ['password', 'confirmPassword'];
      if (this.show === 'password') {
        temp.push('oldPassword');
      }
      this.$refs.passwordForm.validateFields(temp).then(() => {
        this.loading = true;
        personalApi
          .updatePassword({
            ...this.passwordForm,
            id: this.cache.user.userId,
          })
          .then(() => {
            localStorage.clear();
            this.show = 'reLogin';
          })
          .finally(() => (this.loading = false));
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  .login-logo {
    flex: 1;
    background: linear-gradient(90deg, #7e98ff 0%, #5979f8 100%);
    position: relative;
    .copyright {
      position: absolute;
      color: rgba(255, 255, 255, 1);
      left: 32px;
      bottom: 20px;
    }
  }
  .login-img {
    flex: 2;
    background: url('~@/assets/images/login.png') no-repeat center;
    background-size: 100% 100%;
    background-position: right;
  }
  .login-form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    max-width: 80%;
    width: 33%;
    padding: 4.5% 4.5%;
    background-color: #ffffff;
    box-shadow: 0px 16px 30px 0px rgba(104, 134, 251, 0.3);
    border-radius: 20px;
    :deep(.ant-form label) {
      font-size: 16px;
    }
    .title {
      font-weight: bold;
      margin-bottom: 3vw;
      img {
        width: 60%;
        height: auto;
      }
    }
    :deep(.ant-form-item) {
      //margin-bottom: 40px;
      .anticon-eye,
      .anticon-eye-invisible {
        color: @primary-color;
      }
    }
    :deep(.ant-form-item-label) {
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; // 兼容 IE
    }
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper),
    :deep(.ant-input-affix-wrapper-focused) {
      border-radius: inherit;
      border-width: 0px 0px 1px 0px !important;
      box-shadow: none;
      &:hover,
      &:focus {
        border-right-width: 0px !important;
        box-shadow: none;
      }
    }
    .code-img {
      position: absolute;
      right: 0;
      top: -6px;
      cursor: pointer;
      :deep(.ant-image-img) {
        width: auto;
        height: 100%;
      }
    }
    .btn {
      margin: 20px 0 10px 0;
      height: 46px;
      font-size: 16px;
    }
    .link {
      font-size: 14px;
      color: #b0bec5;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
  }
}
</style>
